import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import banner from "../assets/images/banner.png";
import { Navbar as DesktopNavbar } from "../components/Navbar/Desktop/Navbar";
import flybigLogoSearchNav from "../assets/svgs/flybigLogoSearchNav.svg";
import { useWindowSize } from "../utils/utils";
import { Navbar } from "../components/Navbar/Mobile";
import { colorPalette } from "../theme/color-palette";
import { useNavigate } from "react-router-dom";
import { searchUiSectionsSorted } from "../Api/search";
import parse from "html-react-parser";
import "./WeatherAdvisory.scss";

const WeatherAdvisory = () => {
  const [cocData, setCocData] = useState({});
  const [cocContent, setCocContent] = useState({});

  useEffect(() => {
    searchUiSectionsSorted(encodeURIComponent("Weather Advisory")).then(({ data }) => {
      setCocData(data);
      setCocContent(data?.details?.[0]);
    });
  }, []);

  const navigate = useNavigate();

  const [innerWidth] = useWindowSize();
  const isMobile = innerWidth < 640;

  const { white500, primary500 } = colorPalette;

  return (
    <div>
      {isMobile ? (
        <Navbar
          color={white500}
          bgColor={primary500}
          label={"Terms and Conditions"}
          showMenu={false}
          onClose={() => navigate.go(-1)}
        />
      ) : (
        <div className='nav-container-search-page bg-primary'>
          <DesktopNavbar
            logo={flybigLogoSearchNav}
            customClass='navbar-search'
          />
        </div>
      )}

      <img className='w-full cover' src={banner} alt='banner' />
      <Container className='mt-12'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={3}>
            <div className='sticky top-0 shadow-lg py-4 px-4 '>
              {cocData?.details?.map((item) => (
                <p
                  className='weather-menu border-bottom py-4 p-1'
                  onClick={(e) => {
                    setCocContent(item);
                    console.log(
                      e.currentTarget,
                      e.currentTarget.value,
                      e.target
                    );
                  }}
                >
                  {item?.title}
                </p>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <div className='ml-10'>
              <h2>{cocContent?.heading}</h2>
              <h3 className='py-4 text-justify'>{cocContent?.title}</h3>
              {cocContent?.description?.text && (
                <p className='gotham-book text-justify'>
                  {parse(cocContent?.description?.text)}
                </p>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default WeatherAdvisory;
