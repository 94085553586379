import { Grid, MenuItem, TextField, Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  DATE_FORMAT,
  Adult,
  Child,
  Infant,
  SALUTATION_CHILD,
  SALUTATION_ADULT,
} from "../../../../utils/constant";
import { getFormattedDate, nameHandler } from "../../../../utils/utils";
import DatePicker from "react-mobile-datepicker";
import { CustomDrawer } from "../../../common/Drawer";
import moment from "moment";
import SelectComp from "../../../common/SelectComp";

const monthMap = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const dateConfig = {
  date: {
    format: "DD",
    caption: "Day",
    step: 1,
  },
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: "Mon",
    step: 1,
  },
  year: {
    format: "YYYY",
    caption: "Year",
    step: 1,
  },
};

const UserDetails = ({
  userData,
  setUserData,
  onChangeHandler,
  isValidFName,
  isValidLName,
  setIsValidFName,
  setIsValidLName,
  allCountries,
  type,
  travellerList,
  handleSavedTraveller,
}) => {
  const [state, setState] = useState({
    time: userData?.birthday ? new Date(userData?.birthday) : new Date(),
    isOpen: false,
  });
  const [dateDrawer, setDateDrawer] = useState({ bottom: false });
  const [minMaxDate, setMinMaxDate] = useState({});

  useEffect(() => {
    if (type === Adult) {
      setMinMaxDate(() => {
        return {
          min: new Date(moment().subtract(100, "Y").toString()),
          max: new Date(moment().subtract(12, "Y").toString()),
        };
      });
    } else if (type === Child) {
      setMinMaxDate(() => {
        return {
          min: new Date(moment().subtract(12, "Y").toString()),
          max: new Date(moment().subtract(2, "Y").toString()),
        };
      });
    } else if (type === Infant) {
      setMinMaxDate(() => {
        return {
          min: new Date(moment().subtract(2, "Y").toString()),
          max: new Date(),
        };
      });
    }
  }, []);

  const { DATE2 } = DATE_FORMAT;

  const SALUTATION = type === Adult ? SALUTATION_ADULT : SALUTATION_CHILD;

  const handleCancel = () => {
    setState({ isOpen: false });
    setDateDrawer({ bottom: false });
  };

  const handleSelect = (time) => {
    setState({ time, isOpen: false });
    onChangeHandler("birthdate", getFormattedDate(time, DATE2));
    setDateDrawer({ bottom: false });
  };

  return (
    <div className='mt-2'>
      <Box
        component='form'
        sx={{
          "& .MuiTextField-root": {},
        }}
        noValidate
        autoComplete='off'
      >
        <Grid container spacing={2} className='passenger-inputs'>
          <Grid item xs={5}>
            <p>Saved travelers</p>
          </Grid>
          <Grid item xs={7}>
            <SelectComp
              list={travellerList}
              clickHandler={handleSavedTraveller}
              openPax={userData}
            />
          </Grid>
          <Grid item lg={2} sm={2} xs={4}>
            <p className='text-xxs font350 text-gray700 mb-2'>Salutation</p>

            <div className='pssngr-input'>
              <TextField
                select
                size='small'
                value={userData?.salutation}
                onChange={(e) => onChangeHandler("salutation", e.target.value)}
                variant='outlined'
              >
                {SALUTATION.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item lg={4} sm={4} xs={8} className='w-full'>
            <p className='text-xxs font350 text-gray700 mb-2'>First Name</p>
            <TextField
              type='text'
              required
              size='small'
              variant='outlined'
              className='w-full'
              value={userData?.firstName}
              error={userData?.firstName && !isValidFName}
              helperText={
                userData?.firstName && !isValidFName ? "Invalid First Name" : ""
              }
              onChange={(e) => {
                onChangeHandler("firstName", e.target.value);
                setIsValidFName(nameHandler(e.target.value));
              }}
            />
          </Grid>
          <Grid item lg={4} sm={4} xs={12}>
            <p className='text-xxs font350 text-gray700 mb-2'>Last Name</p>
            <TextField
              type='text'
              required
              size='small'
              variant='outlined'
              className='w-full'
              value={userData?.lastName}
              error={userData?.lastName && !isValidLName}
              helperText={
                userData?.lastName && !isValidLName ? "Invalid Last Name" : ""
              }
              onChange={(e) => {
                onChangeHandler("lastName", e.target.value);
                setIsValidLName(nameHandler(e.target.value));
              }}
            />
          </Grid>
          <Grid item lg={5} sm={5} xs={12} className='input-custom'>
            <p className='text-xxs font350 text-gray700 mb-2'>Nationality</p>

            <Autocomplete
              autoComplete
              id='combo-box-demo'
              options={allCountries}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              openOnFocus
              includeInputInList
              value={userData?.nationality}
              onChange={(e, val) => {
                onChangeHandler("nationality", val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          {userData?.nationality?.name !== "India" && (
            <>
              {" "}
              <Grid item lg={5} sm={5} xs={12} className='input-custom'>
                <p className='text-xxs font350 text-gray700 mb-2'>
                  Passport Number
                </p>
                <TextField
                  type='text'
                  required
                  size='small'
                  variant='outlined'
                  className='w-full'
                  value={userData?.passportNo}
                  onChange={(e) =>
                    onChangeHandler("passportNo", e.target.value)
                  }
                />
              </Grid>
              <Grid item lg={5} sm={5} xs={12} className='input-custom'>
                <p className='text-xxs font350 text-gray700 mb-2'>
                  Passport Expiry Date
                </p>
                <TextField
                  type='text'
                  required
                  size='small'
                  variant='outlined'
                  className='w-full'
                  value={userData?.passportExp}
                  onChange={(e) =>
                    onChangeHandler("passportExp", e.target.value)
                  }
                />
              </Grid>
            </>
          )}
          <Grid item lg={5} sm={5} xs={12} className='input-custom'>
            <p className='text-xxs font350 text-gray700 mb-2'>Date Of Birth</p>
            <TextField
              type='text'
              required
              size='small'
              variant='outlined'
              className='w-full'
              value={userData?.birthdate}
              onClick={() => setDateDrawer({ bottom: true })}
            />
          </Grid>
        </Grid>
        <CustomDrawer
          position={"bottom"}
          state={dateDrawer}
          setState={(val) => setDateDrawer(val)}
          label={""}
        >
          <div className='birthDay-datepicker'>
            <DatePicker
              value={state.time}
              isOpen={state.isOpen}
              onSelect={handleSelect}
              onCancel={handleCancel}
              isPopup={false}
              showHeader={false}
              dateConfig={dateConfig}
              confirmText='OK'
              cancelText='Cancel'
              min={minMaxDate?.min}
              max={minMaxDate?.max}
            />
          </div>
        </CustomDrawer>
      </Box>
    </div>
  );
};

export default UserDetails;

// <Grid container className='mt-4'>
//   <Grid item lg={5} xl={4} sm={4} xs={12}>
//     <section className='flex items-center h-full '>
//       <img src={CheckboxBaseSvg} alt='checkbox' />
//       <span className='font325 text-gray600 ml-2'>
//         Save This Passenger Info
//       </span>
//     </section>
//   </Grid>

//   <Grid item lg={5} xl={5} sm={4} xs={12}>
//     <section className='flex wheelChair_assistence'>
//       <FormGroup>
//         <FormControlLabel
//           control={<Switch />}
//           label='Wheelchair Assistance Required'
//           className='font350 text-xs'
//         />
//       </FormGroup>
//     </section>
//   </Grid>
// </Grid>
