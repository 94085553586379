import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userLogOut } from '../../../Api/login';
import {
  logOutHandler,
  toastHandler,
} from '../../../Redux/userReducer/user.action';
import { useDispatch, useSelector } from 'react-redux';
import MyBookingIcon from '../../../assets/svgs/myBooking.svg';
import ProfileIcon from '../../../assets/svgs/profile.svg';
import LogoutIcon from '../../../assets/svgs/logout.svg';
import { Avatar, Divider } from '@mui/material';
import { colorPalette } from '../../../theme/color-palette';

const ProfileDropdown = ({ loggedUser, avatarBg }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { openToast },
  } = useSelector((state) => state);
  const { orange400 } = colorPalette;

  const handleProfile = () => {
    navigate('/my-profile');
  };

  const handleLogout = () => {
    const refreshToken = JSON.parse(localStorage.getItem('user'))?.tokens
      ?.refresh?.token;

    const bookingRoutes = [
      '/search-result',
      '/review-flight',
      '/passenger-info',
    ];

    userLogOut({ refreshToken })
      .then((data) => {
        localStorage.clear();
        dispatch(logOutHandler());
        if (bookingRoutes.indexOf(window.location.pathname) > -1) {
          navigate('/');
        }
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: 'error',
          })
        );
      });
  };

  const handleMyBookings = () => {
    navigate('/my-bookings');
  };

  return (
    <div className='profile-popover absolute mr-16 p-3 z-10'>
      <div className='flex justify-start items-center py-1'>
        <Avatar sx={{ background: orange400 }} className=''>
          {loggedUser?.name[0].toUpperCase()}
        </Avatar>
        <div className='ml-4'>
          <p className='text-sm font-normal'>{loggedUser?.name} </p>
          {/* <p className="text-link500 cursor-pointer">Edit Profile</p> */}
        </div>
      </div>
      <Divider className='my-4' />
      <ul>
        <li
          className='mb-3 cursor-pointer flex items-center'
          onClick={handleMyBookings}
        >
          <img src={MyBookingIcon} alt='my booking' />
          <span className='ml-2'>My Bookings</span>
        </li>
        <li
          className='mb-3 cursor-pointer flex items-center'
          onClick={handleProfile}
        >
          <img src={ProfileIcon} alt='profile' />
          <span className='ml-2'>My Profile & Settings</span>
        </li>
        <li className='cursor-pointer flex items-center' onClick={handleLogout}>
          <img src={LogoutIcon} alt='profile' />
          <span className='ml-2'>Log out</span>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
