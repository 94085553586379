import React from "react";
import "./offers.scss";
import "../../theme/stylesheet.scss";
import { Grid, Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
// import faqBanner from '../../assets/images/faqBanner.png';
import Offer from "./Common/offer/offer";
import { Navbar } from "../Navbar/Desktop/Navbar";
import { Navbar as MobileNavigationBar } from "../Navbar/Mobile/index";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { colorPalette } from "../../theme/color-palette"
import { useWindowSize } from "../../utils/utils";
// import { useHistory } from 'react-router-dom';

import offer1 from '../../assets/images/offers/offer1.png'
import offer2 from '../../assets/images/offers/offer2.png'
import offer3 from '../../assets/images/offers/offer3.png'
import offer4 from '../../assets/images/offers/offer4.png'
import offer5 from '../../assets/images/offers/offer5.png'
import offer6 from '../../assets/images/offers/offer6.png'
import offer7 from '../../assets/images/offers/offer7.png'
import offer8 from '../../assets/images/offers/offer8.png'
import offer9 from '../../assets/images/offers/offer9.png'

import { useNavigate } from "react-router-dom";

const { white500, primary500 } = colorPalette;
const offers = [
  {
    title: 'flybig easy food',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer1
  },
  {
    title: '28% off on IXA to CCA',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer2
  },
  {
    title: 'flubig Seat Free',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer3
  },
  {
    title: 'Priority check in',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer4
  },
  {
    title: 'Mobikwik cashBack offer',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer5
  },
  {
    title: 'Oxalis',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer6
  },
  {
    title: 'HSBC Card Offer',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer7
  },
  {
    title: 'Ahmedabad aapke dwaar',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer8
  },
  {
    title: 'flybig quick offer',
    shortDescription: 'A recognized leader in language immersion & early education, opening second school.',
    expiryDate: '31/08/2022',
    image: offer9
  }
]

export const Offers = () => {
  const [innerWidth] = useWindowSize();
  const navigate = useNavigate();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  return (

    <CssBaseline>
      {!isMobile ? <div className='nav-container-search-page bg-primary'>
        <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
      </div> :
        <MobileNavigationBar
          color={white500}
          bgColor={primary500}
          label={"FAQs"}
          showMenu={false} />
      }

      <Grid container direction="column" sx={{ marginBottom: '65px' }}>
        {/* Banner Image */}
        <div className="offers_bannerImage">
        </div>
      </Grid>
      <Container className="offers_container">
        <Grid item container direction="column" xs={12} md={8} lg={8} sx={{marginTop:'30px'}}>
          <h3 className="offers_Heading"> Offers </h3>
          <p className="offers_SubHeading">
            Everything you need to know about the product and billing. Can’t find the answer you’re looking for? Please <a href="#">chat to our friendly team</a>.
          </p>
        </Grid>
      </Container>
      <Container>
        <Grid item
          container
          spacing={1}
          sx={{ marginTop: '60px' }}>
          {offers.map((offer, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} sx={{ marginBottom: '65px' }}>
                <Offer offer={offer}
                  handelViewOfferButtonClick={function() {navigate('/OfferDetails')}} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </CssBaseline>
  );
};

