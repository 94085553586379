import React, { useState } from "react";
import flybigLogoSearchNav from "../../../assets/svgs/flybigLogoSearchNav.svg";
import { Box, Tab, Tabs } from "@mui/material";
import "./MyBookingsAndProfile.scss";
import { Navbar } from "../../Navbar/Desktop/Navbar";
import { MyBooking } from "./myBooking/MyBooking";
import { MyProfile } from "./myProfile/MyProfile";
import PositionedSnackbar from "../../common/Toast";
import { useSelector } from "react-redux";

export const MyBookingsAndProfile = ({ tabV }) => {
  const [tabValue, setTabValue] = useState(tabV);

  const {
    user: { isLogin },
  } = useSelector((state) => state);

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    if (tabValue === index) {
      return {
        className: `active-tab-custom-booking simple-tab-${index}-bookings`,
      };
    } else {
      return { className: `simple-tab-${index}-bookings` };
    }
  }

  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="my-bookings-container w-full m-auto">
        <div className="nav-container-search-page bg-primary">
          <Navbar logo={flybigLogoSearchNav} customClass="navbar-search" />
        </div>

        {isLogin ? (
          <Box className="w-max-desk m-auto mt-8 px-8 min-h-screen">
            <Box className="tabs-box-my-bookings-tabs m-auto">
              <Tabs
                value={tabValue}
                onChange={handleChangeTabs}
                indicatorColor="none"
              >
                <Tab label="My Bookings" {...a11yProps(0)} />
                <Tab label="My Profile & Settings" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <Box className="my-bookings-main-tabpanel px-6">
              <TabPanel value={tabValue} index={0}>
                <MyBooking />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <MyProfile />
              </TabPanel>
            </Box>
          </Box>
        ) : (
          <div className={"min-h-screen flex items-center justify-center"}>
            <h1>Thank you for visiting flybig!</h1>
          </div>
        )}
      </div>
      <PositionedSnackbar />
    </>
  );
};
