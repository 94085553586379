import { CircularProgress, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../Navbar/Mobile";
import { colorPalette } from "../../../theme/color-palette";
import PassengerContact from "../common/PassengerContact";
import { MobileAmountFooter } from "../../MobileAmountFooter";
import { CartDetails } from "../../CartDetailsDrawer";
import { UserInfo } from "./UserInfoDrawer";
import { DEFAULT_INFO } from "../../../utils/constant";
import { booking, sendPayment } from "../../../Api/Booking";
import { useNavigate } from "react-router-dom";
import {
  bookingIdHandler,
  toastHandler,
} from "../../../Redux/userReducer/user.action";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "../../../Api/Auth";
import MobileLogin from "../../reviewPage/Mobile/userLoginDrawer/index";
import { getFormattedDate } from "../../../utils/utils";

export const MobilePassengerDetails = ({
  passengersList,
  setPassengersList,
  reviewData,
  setContactInfo,
  contactInfo,
  sessionId,
  allCountries,
  travellerList,
  setTravellerList,
}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [loginDrawer, setLoginDrawer] = useState({ bottom: false });
  const [countryCode, setCountryCode] = useState("+91");
  const [CartDetailsDrawer, setCartDetailsDrawer] = useState({
    bottom: false,
  });
  const [userInfoDrawer, setUserInfoDrawer] = useState({
    top: false,
  });
  const [userData, setUserData] = useState({
    ...DEFAULT_INFO,
    id: Math.random(),
  });
  const [type, setType] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user: { openToast },
    search,
  } = useSelector((state) => state);

  const {
    passengers: { adult, child, infant },
  } = reviewData;

  const { white500, primary500 } = colorPalette;
  const authUser = localStorage.getItem("user");

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [authUser]);

  useEffect(() => {
    if (
      contactInfo?.email &&
      contactInfo?.contact?.number &&
      passengersList?.length ===
        search?.searchDetails?.passengerNumbers?.adult +
          search?.searchDetails?.passengerNumbers?.children +
          search?.searchDetails?.passengerNumbers?.infant
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [contactInfo, passengersList, search?.searchDetails?.passengerNumbers]);

  const onChangeHandler = (fieldName, value) => {
    let userDataClone = { ...userData };
    userDataClone[fieldName] = value;
    setUserData(userDataClone);
  };

  const onSubmitHandler = (id, type) => {
    let passengersListClone = JSON.parse(JSON.stringify(passengersList));
    let index = passengersListClone.findIndex((item) => item.id === id);

    if (index !== -1) {
      passengersListClone = passengersListClone?.filter(
        (item) => item.id !== userData.id
      );
    }
    passengersListClone.push({ ...userData, type });
    setPassengersList(passengersListClone);
    setUserData({ ...DEFAULT_INFO, id: Math.random() });
    setUserInfoDrawer({ bottom: false });
  };

  const bookingHandler = () => {
    setIsLoading(true);
    let passengersListClone = JSON.parse(JSON.stringify(passengersList));
    passengersListClone = passengersListClone.map((obj) => {
      const { id, ...formattedPassengerList } = obj;
      return {
        ...formattedPassengerList,
        nationality: obj?.nationality?.isoCode,
      };
    });
    let body = {
      sessionId: sessionId,
      contact: contactInfo.contact,
      email: contactInfo.email,
      passengers: passengersListClone,
    };
    booking(body)
      .then(({ data }) => {
        dispatch(bookingIdHandler(data));
        return sendPayment(data?.id);
      })
      .then(({ data }) => {
        setIsLoading(false);
        window.location.href = data?.url;
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
        setIsLoading(false);
      });
  };

  const handleLogin = () => {
    setLoginDrawer({ bottom: true });
  };

  const handleSavedTraveller = (openPax, selectedVal) => {
    setUserData({
      ...userData,
      firstName: selectedVal?.firstName,
      lastName: selectedVal?.lastName,
      birthdate: getFormattedDate(selectedVal?.birthdate, "YYYY-MM-DD"),
      salutation: selectedVal?.salutation,
    });
    setTravellerList((prev) => {
      return prev.filter(
        (item) =>
          item.firstName !== selectedVal?.firstName &&
          item.lastName !== selectedVal?.lastName
      );
    });
  };

  return (
    <div className="bg-gray300 h-full pb-4">
      <Navbar
        color={white500}
        bgColor={primary500}
        label={"Add Guest"}
        showMenu={false}
        onClose={() => navigate(-1)}
      />
      <Container className="">
        <div className="mt-4 shadow-lg py-3 px-2 bg-white mb-4">
          <div>
            <h3>Guest Details</h3>
            <UserType
              setUserInfoDrawer={setUserInfoDrawer}
              label={"ADULT"}
              age={"(12 years+)"}
              paxCount={adult}
              type="Adult"
              setType={setType}
              passengersList={passengersList}
              setUserData={setUserData}
            />
            {Number(child) ? (
              <UserType
                setUserInfoDrawer={setUserInfoDrawer}
                label={"CHILD"}
                age={"(2-12 years)"}
                paxCount={child}
                type="Child"
                setType={setType}
                passengersList={passengersList}
                setUserData={setUserData}
              />
            ) : (
              ""
            )}
            {Number(infant) ? (
              <UserType
                setUserInfoDrawer={setUserInfoDrawer}
                label={"INFANT"}
                age={"(0-2 years)"}
                paxCount={infant}
                type="Infant"
                setType={setType}
                passengersList={passengersList}
                setUserData={setUserData}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mb-4 bg-white px-2 pb-6 pt-1 border-rounded user_contact_details">
          <PassengerContact
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
          />
        </div>
      </Container>
      <div>
        <MobileAmountFooter
          label={
            !isAuth ? (
              "Login"
            ) : isLoading ? (
              <span>
                <CircularProgress color="inherit" size={20} />
              </span>
            ) : (
              "Continue"
            )
          }
          CartDetailsDrawer={CartDetailsDrawer}
          setCartDetailsDrawer={setCartDetailsDrawer}
          data={reviewData}
          onClickHandler={isAuth ? bookingHandler : handleLogin}
          isDisabled={isDisabled}
        />
        <CartDetails
          CartDetailsDrawer={CartDetailsDrawer}
          setCartDetailsDrawer={setCartDetailsDrawer}
          data={reviewData}
          usedIn={"passenger-info"}
        />
        <UserInfo
          userInfoDrawer={userInfoDrawer}
          setUserInfoDrawer={setUserInfoDrawer}
          userData={userData}
          setUserData={setUserData}
          onChangeHandler={onChangeHandler}
          onSubmitHandler={onSubmitHandler}
          type={type}
          allCountries={allCountries}
          travellerList={travellerList}
          handleSavedTraveller={handleSavedTraveller}
        />
        <div className="login_drawer_container">
          <MobileLogin
            loginDrawer={loginDrawer}
            setLoginDrawer={setLoginDrawer}
          />
        </div>
      </div>
    </div>
  );
};

const UserType = ({
  label,
  age,
  paxCount,
  setUserInfoDrawer,
  type,
  setType,
  passengersList,
  setUserData,
  count,
}) => {
  return (
    <div>
      <div className="flex justify-between my-3 my-4">
        <p className="text-xxs">
          {label} {age}
        </p>
        <p className="text-xxs text-danger">
          {passengersList?.filter((item) => item.type === type).length}/
          {paxCount} added
        </p>
      </div>
      {passengersList
        ?.filter((item) => item.type === type)
        ?.map((item) => (
          <div className="shadow-sm py-4 px-4 flex justify-between items-center ">
            <p className="text-md">{item?.firstName + " " + item?.lastName}</p>
            <img
              onClick={() => {
                setUserData(item);
                setUserInfoDrawer({ top: true });
                setType(type);
              }}
              src={
                "https://imgak.mmtcdn.com/flights/assets/media/mobile/common/hdpi/rt_t5.png"
              }
              alt="edit"
            />
          </div>
        ))}
      <p
        onClick={() => {
          if (
            passengersList?.filter((item) => item.type === type).length <
            paxCount
          ) {
            setUserInfoDrawer({ top: true });
            setType(type);
            setUserData({ ...DEFAULT_INFO, id: Math.random() });
          }
        }}
        className={`${
          passengersList?.filter((item) => item.type === type).length < paxCount
            ? "text-link500"
            : "text-gray600"
        } text-xs text-center shadow-sm py-4 mt-2`}
      >
        + ADD NEW {label}{" "}
      </p>
    </div>
  );
};
