import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import {
  Adult,
  Child,
  DEFAULT_INFO,
  Infant,
  SALUTATION_ADULT,
  SALUTATION_CHILD,
} from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../../../utils/utils";
import { Country } from "country-state-city";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SelectComp from "../../common/SelectComp";
import { addPassenger } from "../../../Api/Profile";
import { toastHandler } from "../../../Redux/userReducer/user.action";
import { Close } from "@mui/icons-material";

export function PassengerDetails({
  type,
  passengersList,
  setPassengersList,
  travellerList,
  setTravellerList,
  travellerListClone,
}) {
  const [allCountries, setAllCountries] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userDataError, setUserDataError] = useState({
    salutation: false,
    firstName: false,
    lastName: false,
    birthdate: false,
  });
  const [specialAssistance, setSpecialAssistance] = useState({});
  const [assistanceRequired, setAssistanceRequired] = useState({});
  const initRender = useRef(false);
  const errorLabelStyling = {
    color: "#d32f2f",
    fontSize: "12px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    margin: "10px 10px",
  };

  const modifyAssistance = (value, id) => {
    setAssistanceRequired({
      ...assistanceRequired,
      [id]: value,
    });

    if (!value) {
      setSpecialAssistance({ ...specialAssistance, [id]: [] });
    }
  };

  const compareDuplicates = (travellerListClone, value) => {
    return travellerListClone?.some((item) => {
      return (
        item?.salutation === value?.salutation &&
        item?.firstName === value?.firstName &&
        item?.lastName === value?.lastName &&
        getFormattedDate(item?.birthdate, "DD-MM-YYYY") ===
          getFormattedDate(value?.birthdate, "DD-MM-YYYY")
      );
    });
  };
  const capitalType =
    type === Adult ? "adult" : type === Child ? "children" : "infant";

  const addNewPassenger = () => {
    setPassengersList((curr) => {
      return [
        ...curr,
        {
          ...DEFAULT_INFO,
          type,
          id: Math.random(),
        },
      ];
    });
  };

  useEffect(() => {
    if (!initRender.current) {
      for (let i = 0; i < passengerNumbers[capitalType]; i++) {
        addNewPassenger();
      }
    }
    initRender.current = true;
  }, []);

  const userDataHandler = (itm, fieldName, data, id) => {
    if (userDataError[fieldName] && data && data?.length > 0) {
      setUserDataError({ ...userDataError, [fieldName]: false });
    }

    let passengerListClone = JSON.parse(JSON.stringify(passengersList));
    let passengerListData = passengerListClone?.map((item, i) => {
      if (item.id === id) {
        if (fieldName === "number" || fieldName === "expireDate") {
          item["passport"] = { ...item["passport"], [fieldName]: data };
          return {
            ...item,
          };
        } else {
          item[fieldName] = data;
          return {
            ...item,
          };
        }
      } else {
        return { ...item };
      }
    });
    passengerListData = passengerListData.map((element) => {
      if (element?.nationality === "IN") {
        const { passport, ...other } = element;
        return other;
      } else {
        return element;
      }
    });

    setPassengersList(passengerListData);
  };

  const dispatch = useDispatch();
  const {
    search: {
      searchDetails: { passengerNumbers },
    },
    user: { openToast },
  } = useSelector((state) => state);

  const SALUTATION = type === Adult ? SALUTATION_ADULT : SALUTATION_CHILD;

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    allCountries && setAllCountries(allCountries);
  }, []);

  const includeDateIntervals =
    type === Infant
      ? [
          {
            start: new Date(moment().subtract(2, "Y").toString()),
            end: new Date(),
          },
        ]
      : type === Adult
      ? [
          {
            start: new Date(moment().subtract(100, "Y").toString()),
            end: new Date(moment().subtract(12, "Y").toString()),
          },
        ]
      : type === Child
      ? [
          {
            start: new Date(moment().subtract(12, "Y").toString()),
            end: new Date(moment().subtract(2, "Y").toString()),
          },
        ]
      : "";

  const handleSavedTraveller = (openPax, selectedVal) => {
    const passengerList = passengersList?.map((item) => {
      if (item?.id === openPax?.id) {
        return {
          ...item,
          firstName: selectedVal?.firstName,
          lastName: selectedVal?.lastName,
          birthdate: getFormattedDate(selectedVal?.birthdate, "YYYY-MM-DD"),
          salutation: selectedVal?.salutation,
        };
      } else {
        return item;
      }
    });
    setPassengersList(passengerList);
    setTravellerList((prev) => {
      return prev.filter(
        (item) =>
          item.firstName !== selectedVal?.firstName &&
          item.lastName !== selectedVal?.lastName,
      );
    });
  };

  const verifyData = (data) => {
    const error = {};
    let validationError = false;

    const info = {
      salutation: data.salutation,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    Object.keys(info).map((key) => {
      if (!info[key] || info[key]?.length === 0) {
        error[key] = true;
        validationError = true;
      } else {
        error[key] = false;
      }
    });

    setUserDataError(error);

    return validationError;
  };

  const addSavedTraveller = (data) => {
    const traveller = {
      salutation: data?.salutation,
      firstName: data?.firstName,
      lastName: data?.lastName,
      nationality: data?.nationality?.isoCode,
      ...(data?.birthdate?.length > 0 && { birthdate: data?.birthdate }),
      type: data?.type,
    };

    const verificationError = verifyData(traveller);

    if (!verificationError) {
      setIsLoading(true);
      addPassenger(traveller)
        .then(() => {
          setIsLoading(false);
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: "Guest Saved Successfully.",
              severity: "success",
            }),
          );
        })
        .catch((err) => {
          setIsLoading(false);
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: err?.response?.data?.message || "Something went wrong.",
              severity: "error",
            }),
          );
        });
    }
  };

  const addRequest = (val, id, itm) => {
    if (specialAssistance[id]) {
      setSpecialAssistance({
        ...specialAssistance,
        [id]: [...specialAssistance[id], val],
      });

      userDataHandler(
        itm,
        "specialAssistance",
        [...specialAssistance[id], val],
        id,
      );
    } else {
      setSpecialAssistance({ ...specialAssistance, [id]: [val] });

      userDataHandler(itm, "specialAssistance", [val], id);
    }
  };

  const removeRequest = (val, id, itm) => {
    setSpecialAssistance({
      ...specialAssistance,
      [id]: specialAssistance[id].filter((value) => value !== val),
    });

    userDataHandler(
      itm,
      "specialAssistance",
      specialAssistance[id].filter((value) => value !== val),
      id,
    );
  };

  const specialAssistanceVals = [
    "Expectant Mother",
    "Blind Passenger",
    "Deaf Passenger",
    "Wheelchair",
  ];

  return (
    <>
      <section className="adult-passenger mt-20">
        <p className="flex justify-between xs-hidden">
          <span className="text-sm font-semibold mb-6">
            {type === Adult
              ? `${type} (12 years+)`
              : type === Child
              ? `${type} (2-12 years)`
              : `${type} (0-2 years)`}
          </span>

          <span className="text-sm font-semibold xs-hidden">
            {passengersList?.filter((item) => item.type === type)?.length} /{" "}
            {passengerNumbers?.[capitalType]} added
          </span>
        </p>

        {passengersList
          ?.filter((item) => item.type === type)
          .map((itm, index) => {
            return (
              <Box
                key={index}
                component="form"
                sx={{
                  "& .MuiTextField-root": {},
                }}
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={4} className="passenger-inputs">
                  <Grid item sm={6}></Grid>
                  <Grid item sm={3}>
                    <p className="text-right">Saved Guests </p>
                  </Grid>
                  <Grid item sm={3}>
                    <SelectComp
                      list={travellerList}
                      clickHandler={handleSavedTraveller}
                      openPax={itm}
                    />
                  </Grid>
                  <Grid item lg={2} sm={2} xs={12}>
                    <p className="text-xxs font350 text-gray700 mb-1">
                      Salutation
                    </p>

                    <div className="pssngr-input">
                      <TextField
                        select
                        size="small"
                        value={itm?.salutation}
                        onChange={(e) => {
                          userDataHandler(
                            itm,
                            "salutation",
                            e.target.value.toUpperCase(),
                            itm?.id,
                          );
                        }}
                        variant="outlined"
                        className="w-full"
                        helperText={
                          userDataError?.salutation
                            ? "Salutation is required"
                            : ""
                        }
                        error={userDataError?.salutation}
                      >
                        {SALUTATION.map((option, index) => (
                          <MenuItem key={index} value={option.toUpperCase()}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Grid>
                  <Grid item lg={5} sm={5} xs={12} className="w-full">
                    <p className="text-xxs font350 text-gray700 mb-1">
                      First Name (& Middle Name, if any)
                    </p>
                    <TextField
                      type="text"
                      required
                      size="small"
                      variant="outlined"
                      className="w-full"
                      placeholder="Enter your first name ..."
                      value={itm?.firstName}
                      onChange={(e) => {
                        userDataHandler(
                          itm,
                          "firstName",
                          e.target.value,
                          itm.id,
                        );
                      }}
                      helperText={
                        userDataError?.firstName ? "First name is required" : ""
                      }
                      error={userDataError?.firstName}
                    />
                  </Grid>
                  <Grid item lg={5} sm={5} xs={12}>
                    <p className="text-xxs font350 text-gray700 mb-1">
                      Last Name
                    </p>
                    <TextField
                      type="text"
                      required
                      size="small"
                      variant="outlined"
                      placeholder="Enter your last name ..."
                      className="w-full"
                      value={itm?.lastName}
                      onChange={(e) => {
                        userDataHandler(
                          itm,
                          "lastName",
                          e.target.value,
                          itm.id,
                        );
                      }}
                      helperText={
                        userDataError?.lastName ? "Last name is required" : ""
                      }
                      error={userDataError?.lastName}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} className="passenger-inputs pt-6">
                  <Grid item lg={6} sm={6} xs={12} className="input-custom">
                    <p className="text-xxs font350 text-gray700 mb-1">
                      Nationality
                    </p>
                    <Autocomplete
                      autoComplete
                      id="combo-box-demo"
                      options={allCountries}
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      openOnFocus
                      includeInputInList
                      value={itm?.nationality}
                      onChange={(event, newValue) => {
                        userDataHandler(itm, "nationality", newValue, itm?.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12} className="input-custom">
                    <p className="text-xxs font350 text-gray700 mb-1">
                      Date Of Birth
                    </p>
                    <DatePicker
                      selected={itm?.birthdate && new Date(itm?.birthdate)}
                      onChange={(date) => {
                        date &&
                          userDataHandler(
                            itm,
                            "birthdate",
                            getFormattedDate(date, "YYYY-MM-DD"),
                            itm.id,
                          );
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormat="dd-MM-yyyy"
                      includeDateIntervals={includeDateIntervals}
                      className="h-9 w-full px-4"
                    />
                    {userDataError?.birthdate && (
                      <div style={errorLabelStyling}>
                        Please select valid birth date
                      </div>
                    )}
                  </Grid>
                  {itm?.nationality?.name !== "India" && (
                    <>
                      <Grid item lg={5} sm={5} xs={12}>
                        <p className="text-xxs font350 text-gray700 mb-1">
                          Passport Number
                        </p>
                        <TextField
                          type="text"
                          required
                          size="small"
                          variant="outlined"
                          placeholder="Enter your passport number"
                          className="w-full"
                          value={itm?.passport?.number}
                          onChange={(e) =>
                            userDataHandler(
                              itm,
                              "number",
                              e.target.value,
                              itm.id,
                            )
                          }
                        />
                      </Grid>
                      <Grid item lg={5} sm={5} xs={12}>
                        <p className="text-xxs font350 text-gray700 mb-1">
                          Passport Expiry Date
                        </p>
                        <TextField
                          type="text"
                          required
                          size="small"
                          variant="outlined"
                          placeholder="YYYY-MM-DD"
                          className="w-full"
                          value={itm?.passport?.expireDate}
                          onChange={(e) =>
                            userDataHandler(
                              itm,
                              "expireDate",
                              e.target.value,
                              itm.id,
                            )
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <div className="mt-2">
                  {/* <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            modifyAssistance(e.target.checked, itm.id);
                          }}
                        />
                      }
                      label='Special Assistance Required?'
                      className='text-xxs font350 text-gray200'
                    />
                  </FormGroup> */}

                  {assistanceRequired[itm.id] && (
                    <select
                      className="mt-2 px-2 py-1"
                      value=""
                      onChange={(e) => {
                        addRequest(e.target.value, itm.id);
                      }}
                    >
                      <option hidden value="">
                        Select any Option(s)
                      </option>
                      {(specialAssistance[itm.id]
                        ? specialAssistanceVals?.filter(
                            (val) =>
                              specialAssistance[itm.id].indexOf(val) === -1,
                          )
                        : specialAssistanceVals
                      )?.map((value, optionId) => (
                        <option key={optionId}>{value}</option>
                      ))}
                    </select>
                  )}

                  {specialAssistance[itm.id] && (
                    <div className="my-2">
                      {specialAssistance[itm.id]?.map((val) => (
                        <div className="inline-block mr-2" key={val}>
                          <div
                            className="flex px-2 py-1 items-center"
                            style={{
                              backgroundColor: "rgb(188, 22, 130)",
                              color: "white",
                              borderRadius: "7px",
                              userSelect: "none",
                            }}
                          >
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => {
                                removeRequest(val, itm.id);
                              }}
                            >
                              <Close fontSize="10" />
                            </div>
                            <span
                              className="text-xs"
                              style={{ cursor: "default" }}
                            >
                              {val}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {!compareDuplicates(travellerListClone, itm) && (
                  <p
                    onClick={() => addSavedTraveller(itm)}
                    className="text-right text-link500 mt-2 cursor-pointer mr-4"
                  >
                    {isLoading ? (
                      <span className="ml-4 text-center">
                        <CircularProgress color="inherit" size={20} />
                      </span>
                    ) : (
                      "+ Save Guest"
                    )}
                  </p>
                )}
                <Divider className="forms-divider" />
              </Box>
            );
          })}

        <Grid container className="mb-4">
          <Grid item lg={5} xl={4} sm={4} xs={12}>
            {/* <section className='flex items-center h-full '>
              <img src={CheckboxBaseSvg} alt='checkbox' />
              <span className='font325 text-gray600 ml-2'>
                Save This Passenger Info
              </span>
            </section> */}
          </Grid>
          <Grid item lg={5} xl={5} sm={4} xs={12}>
            {/* <section className='flex wheelChair_assistence'>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label='Wheelchair Assistance Required'
                  className='font350 text-xs'
                />
              </FormGroup>
            </section> */}
          </Grid>
          <Grid className="xs-hidden" item lg={2} xl={3} sm={4} xs={12}>
            <Button
              variant="text"
              className="text-link500 text-xs font-normal cursor-pointer flex items-center h-full add_new_user uppercase"
              onClick={addNewPassenger}
              disabled={Boolean(
                passengersList?.filter((item) => item.type === type)?.length ===
                  passengerNumbers?.[capitalType],
              )}
            >
              {` + add ${type}`}
            </Button>
          </Grid>
        </Grid>
      </section>
      <Divider />
    </>
  );
}
