import React, { useState } from "react";
import { Divider, Grid } from "@mui/material";
import udanSvg from "../../../../assets/svgs/udanSvg.svg";
import { BsClock } from "react-icons/bs";
import "./FlightResultView.scss";
import { DATE_FORMAT } from "../../../../utils/constant";
import { getFormattedDate, useWindowSize } from "../../../../utils/utils";
import ShowFlightDetails from "../ShowFlightDetails/ShowFlightDetails";
import { FlightType } from "../../../FlightType";
import { searchFareDetails } from "../../../../Api/search";

export default function FlightResultDeskView({
  selectedOnwardFlight,
  handleFlightSelect,
  selectBtnColor,
  setSelectBtnColor,
}) {
  const [isFlightDetails, setSIsFlightDetails] = useState(false);
  const [faresDetails, seFaresDetails] = useState(null);

  const showFlightDetails = () => {
    const fareTypes = selectedOnwardFlight?.fares?.reduce((acc, curr) => {
      return acc ? `${acc},${curr?.fareBasisName}` : curr?.fareBasisName;
    }, "");
    setSIsFlightDetails(!isFlightDetails);
    !isFlightDetails &&
      searchFareDetails(fareTypes).then(({ data }) => {
        seFaresDetails(data);
      });
  };

  const [innerWidth] = useWindowSize();
  let isTab = innerWidth <= 801 && innerWidth !== 0;

  const {
    origin,
    destination,
    departureDate,
    arrivalDate,
    departureTime,
    arrivalTime,
    duration,
    noOfStops,
    availability,
    route,
    schedulePerSegment,
  } = selectedOnwardFlight;

  const flightStops = noOfStops === "0" ? "Non Stop" : "One Stop";

  const { DATE6 } = DATE_FORMAT;

  return (
    <>
      <Grid className="flight-option flex justify-between border w-full mt-8 m-auto">
        <Grid item lg={2} className="flight-cities">
          <div className="h-full">
            <p
              className={`p0-non-stop text-xs font-normal mb-4 pt-4 ${
                selectedOnwardFlight?.isUdaan ? "ml-16" : "ml-4"
              }`}
            >
              {flightStops}
              {"  "}(Flight No. - {selectedOnwardFlight?.flightNumber?.slice(3)}
              )
            </p>
            <div className="flex justify-around items-start">
              {selectedOnwardFlight?.isUdaan && (
                <img src={udanSvg} alt="udaan scheme" width={43} height={46} />
              )}
              <div className="depart-city-info">
                <p className="p1 font-normal text-xxs mt-1">DEPARTURE</p>
                <p className="p2 text-base font-normal mt-1">{departureTime}</p>
                <p className="p3 font-normal text-xs mt-1">{origin?.city}</p>
                <p className="p4 font-light text-xxs mt-1">
                  {getFormattedDate(departureDate, DATE6)}
                </p>
              </div>
              <div className="flight-stops p-4 mt-8">
                {schedulePerSegment?.length > 1 && (
                  <p className="text-center mb-4 gotham-book text-xs">
                    {schedulePerSegment?.map((item, index) => {
                      return (
                        item?.layover && (
                          <span
                            className="text-center gotham-book text-xs ml-2"
                            key={index}
                          >
                            {item?.layover}
                          </span>
                        )
                      );
                    })}
                  </p>
                )}
                <FlightType
                  type={flightStops}
                  noOfStops={parseInt(noOfStops)}
                />
                {schedulePerSegment?.length && (
                  <p className="text-center mt-4 gotham-book text-xs">
                    {schedulePerSegment?.map((item, index) => {
                      return (
                        item?.layover && (
                          <span
                            className="text-center gotham-book text-xs ml-2"
                            key={index}
                          >
                            {item?.destination?.code}
                          </span>
                        )
                      );
                    })}
                  </p>
                )}{" "}
              </div>
              <div className="arrival-city-info">
                <p className="p1 font-normal text-xxs mt-1">ARRIVAL</p>
                <p className="p2 text-base font-normal mt-1">{arrivalTime}</p>
                <p className="p3 font-normal text-xs mt-1">
                  {destination?.city}
                </p>
                <p className="p4 font-light text-xxs mt-1">
                  {getFormattedDate(arrivalDate, DATE6)}
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="my-6"
        />
        <Grid
          item
          lg={1.5}
          className="flight-duration flex justify-center items-center w-full"
        >
          <div className="text-xxs font-light mb-4">Flight Duration</div>
          <div className="text-sm">
            <span className="m-2">
              <BsClock />
            </span>
            {duration}
          </div>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className="my-6"
        />
        {!isTab && (
          <Grid
            item
            lg={1.5}
            className="iternary flex justify-center items-center w-full"
          >
            <p className="text-xxs mb-4">Itinerary Details</p>
            <p
              className="text-sm font-light text-link500 cursor-pointer"
              onClick={showFlightDetails}
            >
              Show Details
            </p>
          </Grid>
        )}

        <div className="flex ">
          {selectedOnwardFlight?.fares.map((item, index) => {
            return (
              <div key={index}>
                <Grid lg={2} item className="flight-type-box" id="biglite-id">
                  <div
                    className="text-white bg-primary text-center p-2 uppercase"
                    id={`header-${item?.type}`}
                  >
                    {item?.fareBasisName}
                  </div>
                  <div className="flex flex-col justify-around items-center m-auto biglite-info bigeasy-info">
                    <p className="text-center text-xxs font-normal">
                      Per Guest
                    </p>
                    <p className="text-center text-base font-normal">
                      ₹ {item?.adult?.basicFare}
                    </p>
                    <p className="text-center font-light text-xxxs text-white seats-avlbl mb-2">
                      {`${availability} SEAT(S) LEFT AT THIS PRICE`}
                    </p>
                    <button
                      className={
                        selectBtnColor?.index === index &&
                        selectBtnColor?.flightNumber ===
                          selectedOnwardFlight?.flightNumber
                          ? `bg-primary text-white cursor-pointer btn-select-flight btn-select-flight-success`
                          : `bg-primary text-white cursor-pointer btn-select-flight`
                      }
                      onClick={() => {
                        setSelectBtnColor({
                          index,
                          flightNumber: selectedOnwardFlight?.flightNumber,
                        });
                        handleFlightSelect(selectedOnwardFlight, item);
                      }}
                      disabled={!item?.adult?.basicFare || !availability}
                    >
                      SELECT
                    </button>
                  </div>
                </Grid>
              </div>
            );
          })}
        </div>
      </Grid>
      {isFlightDetails && <ShowFlightDetails faresDetails={faresDetails} />}
    </>
  );
}
