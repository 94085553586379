import { Grid, Container, Divider, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colorPalette } from "../../../theme/color-palette";
import { Navbar } from "../../Navbar/Mobile";
import styled from "styled-components";
import { MOBILE_CONFIRM_TAB } from "../../../utils/constant";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Card } from "../../MobileCard";
import PaxTable from "../../profileDetails-Bookings/mobile/PaxTable";
import SMSIcon from "../../../assets/svgs/sms.svg";
import Home from "../../../assets/svgs/home.jpg";
import EMAILIcon from "../../../assets/svgs/emailIcon.svg";
import { bookingDetailsEmail, bookingDetailsSms } from "../../../Api/Booking";
import { useUrlExtract } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { toastHandler } from "../../../Redux/userReducer/user.action";
import {useNavigate} from "react-router-dom"

const { white500, primary500, black500, black400 } = colorPalette;

const BookingConfirmation = ({ data, success }) => {
  const [selectionStep, setSelectionStep] = useState(1);
  const [flightData, setFlightData] = useState([]);
  const [flightLocation, setFlightLocation] = useState([...MOBILE_CONFIRM_TAB]);
  const [isLoading, setIsLoading] = useState({
    email: false,
    sms: false,
  });

  const dispatch = useDispatch();
  const {
    user: { openToast },
  } = useSelector((state) => state);
    const navigate = useNavigate();

  const onClickHandler = () => {};

  useEffect(() => {
    if (selectionStep === 1) {
      setFlightData(data?.selectedFare?.schedule);
    } else {
      setFlightData(data?.selectedFare?.returnSchedule);
    }
  }, [selectionStep]);

  useEffect(() => {
    if (data?.tripType === "RoundTrip") {
      let dataClone = [...flightLocation];
      dataClone[0].from = data?.origin;
      dataClone[0].to = data?.destination;
      dataClone[1].from = data?.destination;
      dataClone[1].to = data?.origin;
      setFlightLocation(dataClone);
    }
  }, [data]);

  const { bookingId } = useUrlExtract("bookingId");

  const handleSendEmail = () => {
    setIsLoading({ ...isLoading, email: true });
    bookingDetailsEmail(bookingId)
      .then(() => {
        setIsLoading({ ...isLoading, email: false });
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: "Email sent successfully",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        setIsLoading({ ...isLoading, email: false });
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const handleSendSms = () => {
    setIsLoading({ ...isLoading, sms: true });
    bookingDetailsSms(bookingId)
      .then(() => {
        setIsLoading({ ...isLoading, sms: false });
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: "SMS sent successfully",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        setIsLoading({ ...isLoading, sms: false });
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };

  const goHome = () => {
      navigate("/");
  }

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        label={"Flight Details"}
        showMenu={false}
      />
      <div>
        <Grid container>
          {data?.tripType === "RoundTrip"
            ? MOBILE_CONFIRM_TAB?.map((item, index) => (
                <Tab
                  onClick={() => setSelectionStep(index + 1)}
                  item
                  key={index}
                  selected={index + 1 === selectionStep}
                  xs={6}
                >
                  <Container>
                    <div className='flex items-center'>
                      <img src={item.url} alt='flight' />
                      <span className='ml-2'>{item?.from}</span>
                      <ArrowForwardIcon className='mx-2' />
                      <span>{item.to}</span>
                    </div>
                  </Container>
                </Tab>
              ))
            : ""}
        </Grid>
      </div>
      {success === "true" ? (
        <Container className='shadow-lg'>
          <div className='h-14 flex justify-between items-center'>
            <p>PNR: {data?.bookingId}</p>
            <p className='text-green500'>Booked</p>
          </div>
        </Container>
      ) : (
        ""
      )}
      <div className='mt-4'>
        <Container>
          <Card
            item={flightData?.[0] || data}
            onClickHandler={onClickHandler}
          />
        </Container>
      </div>
      <div className='mt-6 text-white bg-black h-8 '>
        <Container className='h-full'>
          <div className='flex justify-between items-center h-full'>
            <p>Guest Information</p>
            <p>E-ticket</p>
          </div>
        </Container>
      </div>
      <Container className='mb-4'>
        <ol>
          {data?.tickets?.map((item, index) => (
            <li key={index} className='flex justify-between mt-4'>
              <span className='text-xxs'>{item?.passengerName}</span>
              <span className='text-xxs'>{item?.ticket}</span>
            </li>
          ))}
        </ol>
      </Container>

      <Divider className='my-4' />
      <p className='text-sm font-medium px-4'>
        Seats, Meal and Baggage Information
      </p>
      <Divider className='my-4' />
      <div className='mb-24 p-2'>
        <PaxTable flightData={flightData} passengers={data?.passengers} />
      </div>

      <div className='shadow-md fixed bottom-0 w-full bg-white py-4 flex justify-center items-center'>
        <section className='mr-6 text-center' onClick={handleSendEmail}>
          {!isLoading?.email ? (
            <img
              src={EMAILIcon}
              alt='email'
              width={30}
              height={30}
              className='mb-1'
            />
          ) : (
            <CircularProgress color='inherit' size={30} />
          )}
          <p className='text-primary text-xs'>EMAIL</p>
        </section>

        <section className='ml-6 text-center' onClick={handleSendSms}>
          {!isLoading?.sms ? (
            <img
              src={SMSIcon}
              alt='sms'
              width={30}
              height={30}
              className='mb-1'
            />
          ) : (
            <CircularProgress color='inherit' size={30} />
          )}

          <p className='text-primary text-xs'>SMS</p>
        </section>
        <section className='ml-6 text-center' onClick={goHome}>
            <img
                src={Home}
                alt='home'
                width={30}
                height={30}
                className='mb-1'
            />
            <p className='text-primary text-xs'>GO HOME</p>
          </section>
      </div>
    </div>
  );
};

const Tab = styled(Grid)`
  color: ${({ selected }) => (selected ? black500 : black500)};
  text-align: center;
  font-size: 14px;
  border-bottom: ${({ selected }) =>
    selected ? "3px solid" + primary500 : "1px solid" + black400};
  padding: 14px 0px;
  &:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export default BookingConfirmation;
