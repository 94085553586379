import React, { useEffect, useState } from "react";
import "./faq.scss";
import "../../theme/stylesheet.scss";
import { Grid, Container, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Navbar } from "../Navbar/Desktop/Navbar";
import { Navbar as MobileNavigationBar } from "../Navbar/Mobile/index";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { colorPalette } from "../../theme/color-palette";
import { useWindowSize } from "../../utils/utils";
import FaqGrid from "./Common/faq-grid/faq-grid";
import FaqCollapse from "./Common/faq-collapse/faq-collapse";
import { searchUiSectionsSorted } from "../../Api/search";

const { white500, primary500 } = colorPalette;

export const FAQ = () => {
  const [faqData, setFaqData] = useState({});

  useEffect(() => {
    searchUiSectionsSorted("FAQ").then(({ data }) => {
      setFaqData(data);
    });
  }, []);

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  return (
    <CssBaseline>
      {!isMobile ? (
        <div className='nav-container-search-page bg-primary'>
          <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
        </div>
      ) : (
        <MobileNavigationBar
          color={white500}
          bgColor={primary500}
          label={"FAQs"}
          showMenu={false}
        />
      )}

      <Grid container direction='column'>
        {/* Banner Image */}
        <div className='faq_bannerImage'>
          <img src={faqData?.bannerImage} alt='' />
        </div>
      </Grid>

      <Container>
        <Grid
          item
          container
          direction='column'
          xs={8}
          sx={{ marginTop: "30px" }}
        >
          <h3 className='faqHeading'> {faqData?.title} </h3>
        </Grid>
      </Container>

      <Container>
        <FaqCollapse faqData={faqData} />
      </Container>
    </CssBaseline>
  );
};
