import axiosInstance from "../axiosInstance";
import { BOOKING_ENDPOINTS } from "../endpoints";

const {
  REVIEW_BOOKING,
  BOOKING,
  PAYMENT,
  EMAIL,
  SMS,
  SEAT,
  ANCILLARY,
  PNR,
  LASTNAME,
  CHECKIN,
  GROUP_BY,
  CANCEL,
  UPDATE,
} = BOOKING_ENDPOINTS;

export const reviewBookingData = (id) =>
  axiosInstance.get(REVIEW_BOOKING + `/${id}`);

export const booking = (body) => axiosInstance.post(BOOKING, body);

export const reviewBooking = (body) => axiosInstance.post(REVIEW_BOOKING, body);

export const sendPayment = (bookingId) =>
  axiosInstance.post(BOOKING + "/" + bookingId + PAYMENT);

export const bookingDetailsApi = (id) => axiosInstance.get(BOOKING + `/${id}`);

export const getMyBookings = () => axiosInstance.get(BOOKING);

export const bookingDetailsEmail = (bookingId) =>
  axiosInstance.get(BOOKING + "/" + bookingId + EMAIL);

export const bookingDetailsSms = (bookingId) =>
  axiosInstance.get(BOOKING + "/" + bookingId + SMS);

export const getSeatMap = (id) => axiosInstance.get(BOOKING + `/${id}` + SEAT);

export const postSeatMap = (id, body) =>
  axiosInstance.post(BOOKING + `/${id}` + SEAT, body);

export const getAncillary = () => axiosInstance.get(ANCILLARY);

export const postAncillary = (id, body) =>
  axiosInstance.post(BOOKING + `/${id}` + ANCILLARY, body);

export const getDetailsByPnr = (pnr, lastName) =>
  axiosInstance.get(BOOKING + PNR + pnr + LASTNAME + lastName);

export const postWebCheckIn = (id, body) =>
  axiosInstance.post(BOOKING + `/${id}` + CHECKIN, body);

export const getBookingByGroup = (stage) =>
  axiosInstance.get(BOOKING + GROUP_BY + stage);

export const getBookingByGroupExpanded = (stage) =>
  axiosInstance.get(BOOKING + GROUP_BY + stage + "&expand=true");

export const postCancelBooking = (Id) =>
  axiosInstance.post(BOOKING + "/" + Id + CANCEL);

export const getCancelBooking = (Id) =>
  axiosInstance.get(BOOKING + "/" + Id + CANCEL);

export const fareDetailsCheckin = (id) =>
  axiosInstance.get(BOOKING + "/" + id + PAYMENT);

export const getSeatInfo = (airCraftCode) =>
  axiosInstance.get(BOOKING +"/seatinfo/"+airCraftCode)

export const modifyBooking = (id, body) =>
  axiosInstance.post(BOOKING + "/" + id + UPDATE, body);

export const downloadBoardingPass = (body) =>
  axiosInstance.post(BOOKING +"/getboardingpass",body);

export const sendPassToUser = (body) => 
  axiosInstance.post(BOOKING +"/sendpasstouser",body);


