import React from "react";
import "./offerDetails.scss";
import "../../theme/stylesheet.scss";
import { Grid, Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import faqBanner from '../../assets/images/faqBanner.png';
import { Navbar } from "../Navbar/Desktop/Navbar";
import { Navbar as MobileNavigationBar } from "../Navbar/Mobile/index";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { colorPalette } from "../../theme/color-palette"
import { useWindowSize } from "../../utils/utils";

const { white500, primary500 } = colorPalette;
//Dummy
const offersDetails = [
  "This offer (“Offer”) is being conducted by One Mobikwik Systems Private Limited (“Mobikwik”) for the customers ('Customers') booking IndiGo’s flights (including in combination with IndiGo’s ancillary services) (“Booking”) on IndiGo’s website (goindigo.in) or mobile application, using Mobikwik wallet, in India, during 1st June 2022 – 31st August 2022. (both days included) (“Promotion Period”).",

  "Under this Offer, the Customer will be eligible to receive a flat INR 400 cashback.",

  "The Offer is applicable during the Promotion Period and on a minimum Booking amount of INR 3500 (Rupees Three Thousand Five Hundred).",

  "Offer valid once per user per month (either on flight bookings or on voucher purchase).",

  "To receive Mobikwik cashback under this Offer, the Customer is required to do as follows:",

  "visit lndiGo website or mobile application;",

  "at the time of making the Booking on IndiGo’s website or mobile application, the Customer must select Mobikwik wallet as payment mode. The Customer will then be re-directed to Mobikwik payment page;",

  "once the Customer is re-directed to Mobikwik payment page, the Customer is required to first log-in / sign-up to their Mobikwik account using one-time password (OTP) and thereafter, the Customer is required to click on ‘Apply Coupon’ and insert the promo code ‘INDMBK’ on Mobikwik payment page and complete the payment for the Booking.",

  "Mobikwik cashback will be credited by Mobikwik in the Customer’s Mobikwik wallet by Mobikwik within twenty four (24) hours of the Booking, on a best efforts basis. The timeline provided for sending Mobikwik cashback is only indicative of expected period and may vary from the actual date of credit.",

  "Bookings once made using the promo code shall be final and cannot be modified.",

  "Bookings made on IndiGo’s website or IndiGo’s mobile application will be eligible for this Offer.",

  "The Offer shall be valid on Bookings made during the Promotion Period. The Offer shall be valid once per calendar month during the Promotion Period. The first valid transaction per Customer will be considered",

  "The Offer shall be valid on Bookings made during the Promotion Period. The Offer shall be valid once per calendar month during the Promotion Period. The first valid transaction per Customer will be considered",

  "In case the Customer has any query regarding the Offer, the Customer can visit http://www.mobikwik.com/help.",

  "IndiGo shall not be liable for any loss, damage or personal injury that may be suffered, by a Customer, directly or indirectly, as a result of participating in the Offer.",

  "Mobikwik and IndiGo reserve the right to extend, withdraw or terminate this Offer without prior notice. Further, Mobikwik and IndiGo reserve the right to add, amend or replace all or any of the terms of this Offer, whether similar to this Offer or not, without prior notice and from time to time, without assigning any reasons.",

  "If this Offer and/or anything to be done by Mobikwik in respect of the Offer is prevented or delayed by causes, circumstances or events beyond their reasonable control, including but not limited to computer viruses, tampering, unauthorized intervention, interception, fraud, technical failures, floods, fires, accidents, earthquakes, riots, explosions, wars, hostilities, acts of government or other any causes, then neither IndiGo nor Mobikwik shall be liable for any consequence to the extent so prevented or delayed.",

  "This Offer cannot be clubbed with any other offer.",

  "The Offer is non-transferable, non–exchangeable and non-encashable.",

  "All Bookings made under this Offer shall be subject to IndiGo’s conditions of carriage available at goindigo.in.",

  "Only resident citizens of India of eighteen (18) years or above age are eligible to accept this Offer. Hold bookings or group bookings for IndiGo flights, and IndiGo flight bookings made through travel agents, or by corporates, or through platforms other than IndiGo’s website or mobile application, are not eligible for the Offer.",

  "By making the Booking using Mobikwik wallet during the Promotion Period, the Customer: (i) unconditionally and irrevocably agrees to these terms and conditions; and (ii) consents that all information provided by the Customer may be shared by IndiGo with its agents, representatives, service providers and employees, and may be used to send promotional information pertaining to IndiGo to the Customer, in future.",

  "These Offer terms shall be governed by the laws of India. Any dispute arising out of this Offer shall be subject to the exclusive jurisdiction of competent courts in Delhi."
];


export const OfferDetails = () => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  return (

    <CssBaseline>
      {!isMobile ? <div className='nav-container-search-page bg-primary'>
        <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
      </div> :
        <MobileNavigationBar
          color={white500}
          bgColor={primary500}
          label={"FAQs"}
          showMenu={false} />
      }
      <Container className="offerDetails_section">
        <Grid container direction="column" sx={{ marginBottom: '65px' }}>
          {/* Banner Image */}
          <div>
            <img src={faqBanner} width='100%' />
          </div>
        </Grid>
        <Container className="offerDetails_headerSection">
          <Grid item container direction="column" sx={{ marginTop: '30px' }}>
            <h3 className="offerDetails_heading"> About the offer </h3>
            <p className="offerDetails_subHeading">
              Pay via MobiKwik wallet and get Flat INR 400 cashback. Use Promo Code “INDMBK” on MobiKwik wallet payments page. Offer valid from 1st June 2022 till 31st August 2022. Valid on all days.
            </p>
          </Grid>
        </Container>
        <Container>
          <Grid item
            container
            spacing={1}
            sx={{ marginTop: '60px' }}>
            <ol className="offerDetails_ol">
              {offersDetails.map((offerDetail, index) => {
                return (
                  <li className="offerDetails_list" >{offerDetail}</li>
                );
              })}
            </ol>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

