import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import "./index.scss";
import { colorPalette } from "../../../theme/color-palette";
import { getFormattedDate } from "../../../utils/utils";

export const DateRange = ({
  state,
  handleDayClick,
  numberOfMonths,
  canChangeMonth,
  disabledDays,
  selectedDays,
  fareCalender,
}) => {
  function RenderDay(day) {
    const { gray600 } = colorPalette;
    let isFoldable = window.innerWidth < 300;

    const date = day.getDate();
    const dateStyle = {
      bottom: 0,
      right: 0,
      fontSize: 14,
    };
    const priceStyle = {
      textAlign: "center",
      color: gray600,
      fontSize: "8px",
    };
    const cellStyle = {
      height: isFoldable ? 25 : "100%",
      width: isFoldable ? 25 : "100%",
      textAlign: "center",
    };
    const formattedDate = getFormattedDate(new Date(day), "YYYY-MM-DD");

    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{date}</div>
        {fareCalender && (
          <div style={priceStyle}>
            {fareCalender?.[formattedDate]
              ? `₹ ${fareCalender?.[formattedDate]}`
              : "..."}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <DayPicker
        className='Range'
        numberOfMonths={numberOfMonths}
        canChangeMonth={canChangeMonth || true}
        month={state?.from}
        fromMonth={state?.from}
        selectedDays={
          selectedDays
            ? selectedDays
            : [state.from, { from: state.from, to: state.enteredTo }]
        }
        disabledDays={disabledDays ? disabledDays : { before: new Date() }}
        modifiers={{ start: state?.from, end: state?.to }}
        onDayClick={handleDayClick}
        pagedNavigation
        renderDay={RenderDay}
      />
    </div>
  );
};
