import React, { useEffect, useState } from "react";
import "./aboutUs.scss";
import "../../theme/stylesheet.scss";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Navbar } from "../Navbar/Desktop/Navbar";
import { Navbar as MobileNavigationBar } from "../Navbar/Mobile/index";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { colorPalette } from "../../theme/color-palette";
import { useWindowSize } from "../../utils/utils";
import { searchUiSectionsSorted } from "../../Api/search";
import parse from "html-react-parser";

const { white500, primary500 } = colorPalette;

export const AboutUs = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    searchUiSectionsSorted("About").then(({ data }) => {
      setContent(data);
    });
  }, []);

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  return (
    <CssBaseline>
      <Grid container direction="column" className="aboutUsContainer">
        {!isMobile ? (
          <div className="nav-container-search-page bg-primary">
            <Navbar logo={flybigLogoSearchNav} customClass="navbar-search" />
          </div>
        ) : (
          <MobileNavigationBar
            color={white500}
            bgColor={primary500}
            label={"About us"}
            showMenu={false}
          />
        )}

        {/* Banner Image */}
        {/* <div className='aboutus_bannerImage'>
          <img src={content?.bannerImage} alt='' />
        </div> */}

        {/* About us */}
        <Grid item style={{ paddingLeft: "0px", marginTop: "44px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1.5rem",
              textAlign: "center",
              px: 3,
            }}
          >
            <h3 className="aboutus_pageHeading ml-12">{content?.title}</h3>

            {content?.details?.map((item, index) => {
              return (
                <Grid
                  container
                  direction={{ xs: "column", md: "row" }}
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  sx={{ maxWidth: "75rem" }}
                >
                  {item?.image?.position === "left" && (
                    <Grid item xs={item?.image?.grid || 6}>
                      <img src={item?.image?.link} width="100%" alt="" />
                    </Grid>
                  )}
                  <Grid item xs={item?.description?.grid || 6}>
                    <h4 className="aboutus_cardHeading">{item?.title}</h4>
                    <div className="aboutus_cardInnerText text-left">
                      {parse(item?.description?.text)}
                    </div>
                  </Grid>
                  {item?.image?.position === "right" && (
                    <Grid item xs={item?.image?.grid || 6}>
                      <img src={item?.image?.link} width="100%" alt="" />
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </CssBaseline>
  );
};
