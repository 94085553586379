import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import "../../theme/stylesheet.scss";
import alertIconSvg from "../../assets/svgs/alert-icon.svg";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { Grid } from "@mui/material";
import PopularRoutesCard from "../common/cards";
import MarqueeAnimation from "../common/marquee";
import { HeroBanner } from "./Desktop/HeroBanner";
import { DATE_FORMAT, ONE_WAY, SliderSettings } from "../../utils/constant";
import Slider from "react-slick";
import { getFormattedDate, useWindowSize } from "../../utils/utils";
import { Search } from "./Mobile/Search";
import { Navbar } from "../Navbar/Desktop/Navbar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { searchDetailsHandler } from "../../Redux/searchReducer/search.action";
import {
  searchPopularRoutes,
  searchUiContent,
  searchUiSections,
} from "../../Api/search";
import { toastHandler } from "../../Redux/userReducer/user.action";
import { colorPalette } from "../../theme/color-palette";
import No_Image from "../../assets/images/No_Image.jpg";
import graybg from "../../assets/images/graybg.jpg";
import { SkeletonComp } from "../common/Skeleton/Skeleton";

export const Home = React.memo(() => {
  const navigate = useNavigate();
  const { DATE4 } = DATE_FORMAT;
  const [selected, setSelected] = useState("Book");
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const dispatch = useDispatch();
  const {
    search: {
      flightList,
      searchDetails: {
        departure,
        arrival,
        attendantType: attendantTypeState,
        passengerNumbers: passengerNumbersState,
        tripType: tripTypeState,
        date: flightDate,
      },
    },
    user: { openToast },
  } = useSelector((state) => state);

  const [tripType, setTripType] = useState(tripTypeState);
  const [departureLocation, setDepartureLocation] = useState(departure);
  const [arrivalLocation, setArrivalLocation] = useState(arrival);
  const [attendantType, setAttendantType] = useState(attendantTypeState);
  const [flightListState, setFlightListState] = useState([]);
  const [activeInput, setActiveInput] = useState(0);
  const [activeDate, setActiveDate] = useState(0);
  const [passengerNumbers, setPassengerNumbers] = useState({
    ...passengerNumbersState,
  });
  const [date, setDate] = useState({ ...flightDate });
  const [anchorElCalender, setAnchorElCalender] = useState(null);
  const [popularRoutes, setPopularRoutes] = useState([]);
  const [uiContent, setUiContent] = useState({
    heroImages: [
      {
        image: graybg,
        key: "no image",
      },
    ],
  });
  const [popularRoutesSliderLength, setPopularRoutesSliderLength] =
    useState("");
  const [uiSections, setUiSections] = useState([]);
  const blogsRef = useRef({});

  //Removed to make the input field empty
  // useEffect(() => {
  //   if (flightList?.length && !departure && !arrival) {
  //     setDepartureLocation(flightList[0]);
  //     setArrivalLocation(flightList[0]?.destinations?.[0]);
  //     setFlightListState(flightList[0]?.destinations);
  //   }
  // }, [flightList, departure, arrival]);

  useEffect(() => {
    const { from, enteredTo, to } = date;
    setDate({
      from: new Date(from),
      to: new Date(to),
      enteredTo: new Date(enteredTo),
    });
  }, [tripType]); // eslint-disable-line react-hooks/exhaustive-deps

  const { primary500, white500 } = colorPalette;

  useEffect(() => {
    setPassengerNumbers(passengerNumbersState);
  }, [passengerNumbersState]);

  const submitDataHandler = () => {
    const { adult, children, infant } = passengerNumbers;
    const { from, to } = date;
    dispatch(
      searchDetailsHandler({
        departure: departureLocation,
        arrival: arrivalLocation,
        attendantType: attendantType,
        passengerNumbers: passengerNumbers,
        tripType: tripType,
        date: date,
      })
    );
    const urlItinerary =
      tripType === "Round Trip"
        ? `${departureLocation?.code}-${
            arrivalLocation?.code
          }-${getFormattedDate(from, DATE4)}_${arrivalLocation?.code}-${
            departureLocation?.code
          }-${getFormattedDate(to, DATE4)}`
        : `${departureLocation?.code}-${
            arrivalLocation?.code
          }-${getFormattedDate(from, DATE4)}`;

    navigate(
      `/search-result?tripType=${tripType[0]}&itinerary=${urlItinerary}&paxType=A-${adult}_C-${children}_I-${infant}&atdType=${attendantType}`
    );
  };

  useEffect(() => {
    searchPopularRoutes()
      .then(({ data }) => {
        setPopularRoutes(data);
        data?.length > 4
          ? setPopularRoutesSliderLength(4)
          : setPopularRoutesSliderLength(data?.length);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  }, []);

  const defaultBlog = {
    image: No_Image,
    key: "coding.jpg",
    link: "",
  };

  useEffect(() => {
    searchUiContent().then(({ data }) => {
      setUiContent(data);
      blogsRef.current = {
        grp1: [
          data?.blogs?.[0] || defaultBlog,
          data?.blogs?.[1] || defaultBlog,
        ],
        grp2: [
          data?.blogs?.[2] || defaultBlog,
          data?.blogs?.[3] || defaultBlog,
        ],
      };
    });
  }, []);

  useEffect(() => {
    searchUiSections().then(({ data }) => {
      const sortedData = data.reduce((acc, curr) => {
        acc[curr?.title] = curr;
        return acc;
      }, {});
      sortedData && setUiSections(sortedData);
    });
  }, []);

  const handlePopularRoute = (item) => {
    const { adult, children, infant } = passengerNumbers;
    dispatch(
      searchDetailsHandler({
        departure: item?.origin,
        arrival: item?.destination,
        attendantType: attendantType,
        passengerNumbers: passengerNumbers,
        tripType: ONE_WAY,
        date: {
          ...date,
          from: new Date(moment().add(1, "days").toDate()),
        },
      })
    );
    navigate(
      `/search-result?tripType=O&itinerary=${item?.origin?.code}-${
        item?.destination?.code
      }-${getFormattedDate(
        new Date(moment().add(1, "days").toDate()),
        DATE4
      )}&paxType=A-${adult}_C-${children}_I-${infant}&atdType=${attendantType}`
    );
  };

  return (
    <>
      <Grid
        container
        sx={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!isMobile ? (
          <div className="container">
            <header className="header">
              <div className="header-info">
                <p className="font350 gotham-book text-xs">
                  <a
                    href="https://paxlinks.com/?&domain=flybig"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Partner Login
                  </a>{" "}
                  | Call Us +91 9910 655 655
                </p>
              </div>
              <div className="nav-container-search-page bg-primary">
                <Navbar
                  logo={flybigLogoSearchNav}
                  customClass="navbar-search"
                />
              </div>
            </header>
            <HeroBanner
              date={date}
              setDate={setDate}
              anchorElCalender={anchorElCalender}
              setAnchorElCalender={setAnchorElCalender}
              tripType={tripType}
              setTripType={setTripType}
              departureLocation={departureLocation}
              setDepartureLocation={setDepartureLocation}
              arrivalLocation={arrivalLocation}
              setArrivalLocation={setArrivalLocation}
              attendantType={attendantType}
              setAttendantType={setAttendantType}
              flightListState={flightListState}
              setFlightListState={setFlightListState}
              flightList={flightList}
              submitDataHandler={submitDataHandler}
              passengerNumbers={passengerNumbers}
              setPassengerNumbers={setPassengerNumbers}
              uiContent={uiContent?.heroImages}
            />
            <div className="flybig-alerts">
              <div className="alerts-left">
                <img src={alertIconSvg} alt="alert icon" />
                flybig Alerts
              </div>
              <div className="alert-animation">
                <MarqueeAnimation
                  content={uiSections?.["Flybig Alerts"]?.details}
                />
              </div>
            </div>
          </div>
        ) : (
          <Search
            selected={selected}
            setSelected={setSelected}
            tripType={tripType}
            setTripType={setTripType}
            departureLocation={departureLocation}
            setDepartureLocation={setDepartureLocation}
            arrivalLocation={arrivalLocation}
            setArrivalLocation={setArrivalLocation}
            attendantType={attendantType}
            setAttendantType={setAttendantType}
            date={date}
            setDate={setDate}
            flightListState={flightListState}
            setFlightListState={setFlightListState}
            activeInput={activeInput}
            setActiveInput={setActiveInput}
            flightList={flightList}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
            passengerNumbers={passengerNumbers}
            setPassengerNumbers={setPassengerNumbers}
            searchDataHandler={submitDataHandler}
          />
        )}
        {popularRoutes?.length ? (
          <div className="popular-routes-box">
            <p className="margin-short-subhead text-xl font-medium ">
              Popular Routes
            </p>
            {/* <p className='margin-short-subsubhead font-normal text-sm'>
              Revisit an old favorite or get a taste of someplace new
            </p> */}

            <div className="carousel-popular-routes">
              <div className="carousel-slider">
                <Slider {...SliderSettings(popularRoutesSliderLength)}>
                  {popularRoutes?.map((item, index) => {
                    return (
                      <PopularRoutesCard
                        key={index}
                        item={item}
                        handlePopularRoute={handlePopularRoute}
                      />
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        ) : (
          <div className="popular-routes-box">
            <SkeletonComp width={"80%"} height={"240px"} />
          </div>
        )}

        {/* <div className="special-offers">
          <p className="margin-short-subhead text-xl font-medium">
            Special Offers
          </p>
          <p className='margin-short-subsubhead font-normal text-sm'>
            Revisit an old favorite or get a taste of someplace new
          </p>

          <div className="carousel-slider">
            <Slider {...SliderSettings(3)}>
              {uiSections?.['Special Offers']?.details?.map((item, index) => {
                return <CarouselComp key={index} item={item} index={index} />;
              })}
            </Slider>
          </div>
        </div> */}

        {/* <div className="travel-planning">

          <Grid container spacing={2} className='grid-container'>
            {blogsRef?.current &&
              Object.keys(blogsRef?.current)?.map((item, index) => (
                <Grid
                  item
                  lg={3}
                  md={5}
                  sm={6}
                  key={index}
                  className='grid-img'
                >
                  {blogsRef?.current?.[item]?.map((val, index) => (
                    <>
                      {val?.link ? (
                        <a href={val?.link} target='_blank' rel='noreferrer'>
                          <img
                            key={index}
                            id={`flex-grid-fig-${index}`}
                            src={val?.image}
                            alt={val?.key}
                          />
                        </a>
                      ) : (
                        <img
                          key={index}
                          id={`flex-grid-fig-${index}`}
                          src={val?.image}
                          alt={val?.key}
                        />
                      )}
                    </>
                  ))}
                </Grid>
              ))}

            <Grid item lg={6} md={10} sm={12} className='grid-img-big'>
              {uiContent?.blogs?.[4] ? (
                <a
                  href={uiContent?.blogs?.[4]?.link}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={uiContent?.blogs?.[4]?.image} alt='inside plane' />
                </a>
              ) : (
                <img src={defaultBlog?.image} alt='inside plane' />
              )}
              <a
                href='https://devblogs.flybig.in/'
                target='_blank'
                rel='noreferrer'
                className='flex justify-center mt-4'
              >
                <Button
                  label={"Visit our blog"}
                  bgColor={primary500}
                  color={white500}
                  width={"50%"}
                />
              </a>
            </Grid>
          </Grid>
        </div> */}

        {/* <div className='news-section'>
          <div className='purple-bullet'>
            <p className='margin-short-subhead text-xl font-medium'>News</p>
          </div>
          <Divider />
          <Grid
            container
            spacing={0}
            mt={0.1}
            className='news-grid font-normal'
          >
            {uiContent?.media?.map((item, index) => {
              return (
                <Grid item md={6} sm={12} key={index}>
                  <p className='my-2'>
                    {getFormattedDate(item?.date, "DD MMM YYYY")}
                  </p>

                  <a href={item?.link} target='_blank' rel='noreferrer'>
                    <p
                      className={`text-base gotham-book pb-4 border-bottom ${
                        !isMobile && "text-ellpsis h-10"
                      }`}
                    >
                      {item.title}
                    </p>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        </div>  */}
      </Grid>
    </>
  );
});
