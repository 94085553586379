import React, { useEffect, useState } from "react";
import {
  ATTENDANT_TYPE,
  TRIP_TYPE,
  DATE_FORMAT,
  ROUNDTRIP,
  searchTabs,
} from "../../../../utils/constant";
import { Container, Divider } from "@mui/material";
import { colorPalette } from "../../../../theme/color-palette";
import { RadioBtnGrp } from "../../../common/radio-buttons/RadioBtnGrp";
import Grid from "@mui/material/Grid";
import "./index.scss";
import arrowSvg from "../../../../assets/svgs/arrows.svg";
import { DropDown } from "../../../common/Dropdown";
import { Button } from "../../../common/Button";
import Location from "./Drawer/Location";
import { Passenger } from "./Drawer/Passenger";
import Calender from "./Drawer/Calender";
import logo from "../../../../assets/svgs/flybigLogoSearchNav.svg";
import login from "../../../../assets/svgs/login.svg";
import {
  getFormattedDate,
  handlePassengerValue,
  handleSwitchCities,
} from "../../../../utils/utils";
import Avatar from "@mui/material/Avatar";
import MobileLoginDrawer from "../../../reviewPage/Mobile/userLoginDrawer";
import { verifyToken } from "../../../../Api/Auth";
import styled from "styled-components";
import { CheckInBox } from "../../../common/checkIn/CheckInBox";
import ProfileDropdown from "../../../Navbar/Mobile/ProfileDropdown";
import { Link } from "react-router-dom";

const { primary500, white500, black500 } = colorPalette;

let dateInitialState = {
  from: null,
  to: null,
  enteredTo: null,
};

export const Search = React.memo(function Search({
  selected,
  setSelected,
  tripType,
  setTripType,
  departureLocation,
  setDepartureLocation,
  arrivalLocation,
  setArrivalLocation,
  attendantType,
  setAttendantType,
  date,
  setDate,
  flightListState,
  setFlightListState,
  activeInput,
  setActiveInput,
  flightList,
  activeDate,
  setActiveDate,
  passengerNumbers,
  setPassengerNumbers,
  searchDataHandler,
}) {
  const [dateClone, setDateClone] = useState({ ...dateInitialState });
  const [passengerNumbersClone, setPassengerNumbersClone] = useState({
    ...passengerNumbers,
  });
  const [loginDrawer, setLoginDrawer] = useState({ bottom: false });
  const [profileDrawer, setProfileDrawer] = useState({ bottom: false });
  const [locationDrawer, setLocationDrawer] = useState({
    bottom: false,
  });
  const [paxDrawer, setPaxDrawer] = useState({
    bottom: false,
  });
  const [calenderDrawer, setCalenderDrawer] = useState({
    bottom: false,
  });
  const [isAuth, setIsAuth] = useState(false);

  const { from, to } = date;
  const { DATE3 } = DATE_FORMAT;
  const userData = localStorage.getItem("user");

  useEffect(() => {
    verifyToken()
      .then(({ data }) => setIsAuth(data))
      .catch((err) => {
        setIsAuth(false);
      });
  }, [userData]);

  const onClickHandler = (value) => {
    setSelected(value);
  };

  const dateOnSubmitHandler = () => {
    setDate({ ...dateClone });
    setCalenderDrawer({
      bottom: false,
    });
  };

  const submitDataHandler = () => {
    setPassengerNumbers({ ...passengerNumbersClone });
    setPaxDrawer({
      bottom: false,
    });
  };

  const openLocationDrawerHandler = () => {
    setLocationDrawer({ bottom: true });
  };

  return (
    <>
      <div className="h-14 text-white w-full mobile-home-nav">
        <Container className="h-full">
          <Grid container className="justify-between items-center h-full py-1">
            <Grid item xs={8}>
              <Link to={"/"}>
                <img src={logo} alt="logo" height={30} />
              </Link>
            </Grid>
            <Grid item xs={4} className="text-right">
              {isAuth ? (
                <div class="dropdown">
                  <Avatar
                    onClick={() => {
                      if (isAuth) {
                        setProfileDrawer({ bottom: true });
                      } else {
                        setLoginDrawer({ bottom: true });
                      }
                    }}
                  >
                    H
                  </Avatar>
                </div>
              ) : (
                <img
                  onClick={() => setLoginDrawer({ bottom: true })}
                  src={login}
                  alt="login_logo"
                  className="login_logo"
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="bg-gray300 py-5 w-full">
        <Container>
          <div className="flex justify-between">
            {searchTabs?.map((item, index) => (
              <Tab
                key={index}
                onClick={() => onClickHandler(item?.value)}
                selected={selected === item?.value}
              >
                {item?.name}
              </Tab>
            ))}
          </div>
        </Container>
      </div>
      {selected === "Book" && (
        <Container>
          <div className="py-2">
            <RadioBtnGrp
              value={tripType}
              handleChange={setTripType}
              inputArray={TRIP_TYPE}
              isRow={true}
            />
          </div>
          <div className="relative border-top border-bottom py-3">
            <Grid container>
              <Grid
                item
                xs={6}
                onClick={() => {
                  openLocationDrawerHandler();
                  setActiveInput(0);
                  setFlightListState(flightList);
                }}
              >
                <div>
                  <p className="text-gray800 text-xxs">FROM</p>
                  <p className="text-black500 text-md py-1">
                    {departureLocation?.code}
                  </p>
                  <p className="text-gray800 text-xxs">
                    {departureLocation?.city}
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                onClick={() => {
                  openLocationDrawerHandler();
                  setActiveInput(1);
                  setFlightListState(departureLocation?.destinations);
                }}
              >
                <div className="text-right">
                  <p className="text-gray800 text-xxs">TO</p>
                  <p className="text-black500 text-md py-1">
                    {arrivalLocation?.code}
                  </p>
                  <p className="text-gray800 text-xxs">
                    {arrivalLocation?.city
                      // ? arrivalLocation?.city
                      // : "Destination"
                      }
                  </p>
                </div>
              </Grid>
            </Grid>
            <div
              onClick={() =>
                handleSwitchCities(
                  flightList,
                  arrivalLocation,
                  departureLocation,
                  setDepartureLocation,
                  setArrivalLocation
                )
              }
              className="arrow-svg"
            >
              <img alt="flight" src={arrowSvg} />
            </div>
          </div>
          <div className="border-bottom py-3 relative">
            <Grid
              container
              onClick={() => {
                setCalenderDrawer({ bottom: true });
              }}
            >
              <Grid item xs={6}>
                <div
                  onClick={() => {
                    setActiveDate(0);
                    setDateClone({ ...dateInitialState });
                  }}
                >
                  <p className="text-gray800 text-xxs">DEPARTURE</p>
                  <p className="text-black500 text-md pt-2">
                    {getFormattedDate(from, DATE3)}
                  </p>
                </div>
                <div className="divider"></div>
              </Grid>
              <Grid item xs={6}>
                <div
                  className="text-right"
                  onClick={() => {
                    setActiveDate(1);
                    setDateClone({ from: from, to: null, enteredTo: null });
                  }}
                >
                  <p
                    className="text-gray800 text-xxs"
                    onClick={() => setTripType("Round Trip")}
                  >
                    RETURN
                  </p>
                  <p className="text-black500 text-md pt-2">
                    {tripType === ROUNDTRIP ? (
                      to ? (
                        getFormattedDate(to, DATE3)
                      ) : (
                        <p className="text-primary flex items-center text-xs justify-end">
                          <span className="text-md">+{"  "} </span>
                          <span> Add Return Date</span>
                        </p>
                      )
                    ) : (
                      <p className="text-primary flex items-center text-xs justify-end">
                        <span className="text-md">+{"  "} </span>
                        <span> Add Return Date</span>
                      </p>
                    )}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div
            className="border-bottom py-3"
            onClick={() => setPaxDrawer({ bottom: true })}
          >
            <p className="text-gray800 text-xxs">TRAVELLERS</p>
            <p className="text-black500 text-md pt-2">
              {Object.keys(passengerNumbers)?.map((item, index) => (
                <span key={index} className="capitalize">
                  {passengerNumbers[item]} {item}
                  {index === 2 ? " " : ", "}
                </span>
              ))}
            </p>
          </div>
          <div className="mobile_search_dropdown_container py-1">
            <DropDown
              value={attendantType}
              handleChange={setAttendantType}
              inputArray={ATTENDANT_TYPE}
              placeholder={""}
            />
          </div>
          <div className="pb-2">
            <Button
              label={"Search flights"}
              color={white500}
              bgColor={primary500}
              size={"medium"}
              variant={"contained"}
              width={"100%"}
              disabled={!arrivalLocation?.code}
              onClickHandler={searchDataHandler}
            />
          </div>
        </Container>
      )}
      {selected === "CheckIn" ? (
        <Container>
          <CheckInBox usedIn="checkin" />
          <Divider />
        </Container>
      ) : (
        selected === "Modify" && (
          <Container>
            <CheckInBox usedIn="manage-booking" />
            <Divider />
          </Container>
        )
      )}
      <div>
        <Location
          locationDrawer={locationDrawer}
          setLocationDrawer={setLocationDrawer}
          departureLocation={departureLocation}
          setDepartureLocation={setDepartureLocation}
          arrivalLocation={arrivalLocation}
          setArrivalLocation={setArrivalLocation}
          flightListState={flightListState}
          setFlightListState={setFlightListState}
          activeInput={activeInput}
          setActiveInput={setActiveInput}
        />
      </div>
      <Passenger
        paxDrawer={paxDrawer}
        setPaxDrawer={setPaxDrawer}
        passengerNumbersClone={passengerNumbersClone}
        handlePassengerValue={handlePassengerValue}
        submitDataHandler={submitDataHandler}
        setPassengerNumbers={setPassengerNumbers}
        passengerNumbers={passengerNumbers}
        setPassengerNumbersClone={setPassengerNumbersClone}
      />
      <Calender
        calenderDrawer={calenderDrawer}
        setCalenderDrawer={setCalenderDrawer}
        date={dateClone}
        setDate={setDateClone}
        activeDate={activeDate}
        setActiveDate={setActiveDate}
        onClickHandler={dateOnSubmitHandler}
        setTripType={setTripType}
        tripType={tripType}
        originalDate={date}
      />
      <MobileLoginDrawer
        loginDrawer={loginDrawer}
        setLoginDrawer={setLoginDrawer}
      />
      <ProfileDropdown
        profileDrawer={profileDrawer}
        setProfileDrawer={setProfileDrawer}
      />
    </>
  );
});

const Tab = styled.div`
  color: ${({ selected }) => (selected ? white500 : black500)};
  background: ${({ selected }) => (selected ? primary500 : white500)};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 10px;
`;
